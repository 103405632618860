import React, { useState, useMemo, useCallback } from 'react'
import {
  isValidEmail,
  isValidCountryCode,
  isValidPhoneNumber,
  cn,
} from '../../lib/helpers'
import { Input } from '../Input'
import { InfoMessage } from '../InfoMessage'
import CustomerSupport from './customerSupport.png'
import Icon from '../icons'
import * as styles from './ContactModal.module.css'
import PropTypes from 'prop-types'
import { Modal } from '../Modal/Modal'

export const ContactModal = ({ onClose }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState('+46')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [position, setPosition] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isErrorResponse, setIsErrorResponse] = useState(false)
  const isEmailValid = useMemo(() => isValidEmail(email), [email])
  const isCountryCodeValid = useMemo(() => isValidCountryCode(countryCode), [
    countryCode,
  ])
  const isPhoneNumberValid = useMemo(() => isValidPhoneNumber(phoneNumber), [
    phoneNumber,
  ])
  const errNo = 'Feltet er påkrevd'
  const phoneErrorMessage = useMemo(() => {
    if (!phoneNumber.length) {
      return 'Phone number is required.'
    } else if (!isPhoneNumberValid) {
      return 'Please enter a valid phone number.'
    }
    return ''
  }, [phoneNumber])

  const countryCodeErrorMessage = useMemo(() => {
    if (!countryCode.length) {
      return 'Landskode er påkrevd '
    } else if (!isCountryCodeValid) {
      return 'Vennligst skriv inn en gyldig landskode'
    }
    return ''
  }, [countryCode])
  const resetForm = useCallback(() => {
    setShowError(false)
    setShowSuccess(true)
    setName('')
    setEmail('')
    setCountryCode('+47')
    setPhoneNumber('')
    setPosition('')
    setCompany('')
    setMessage('')
  }, [])

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (
      !isEmailValid ||
      !isCountryCodeValid ||
      !isPhoneNumberValid ||
      !message.length ||
      !name.length
    ) {
      setShowError(true)
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': event.target.getAttribute('name'),
          name,
          email,
          phoneNumber: countryCode + phoneNumber,
          position,
          company,
          message,
        }),
      })
        .then(() => resetForm())
        .catch(() => {
          setIsErrorResponse(true)
          setShowSuccess(false)
        })
    }
  }

  return (
    <Modal onClose={onClose}>
      <form
        name='contact'
        onSubmit={(e) => handleSubmit(e)}
        data-netlify='true'
        method='post'
        netlify-honeypot='bot-field'
        onClick={() => {
          showSuccess ? onClose() : setIsErrorResponse(false)
        }}
      >
        <div className={cn(styles.wrapper, showSuccess && styles.hidden)}>
          <h3 className={styles.title}>
            Fyll i detta formulär, så hör vi av oss
          </h3>
          <input type='hidden' name='form-name' value='contact' />
          <p hidden>
            <label>
              Ikke fyll ut dette feltet om du er et menneske
              <input name='bot-field' />
            </label>
          </p>
          <div className={styles.fieldsContainer}>
            <div className={styles.inputFieldsWrapper}>
              <Input
                value={name}
                onChange={(value) => setName(value)}
                placeholder='Namn*'
                name='name'
                error={showError && !name.length}
                aria-label='Name input'
                className={styles.inputField}
              />
              {showError && !name.length && (
                <InfoMessage message={'Feltet er påkrevd'} />
              )}
              <Input
                value={email}
                onChange={(value) => setEmail(value)}
                error={showError && !isEmailValid}
                errorMessage=''
                placeholder='E-post*'
                name='email'
                type='email'
                className={styles.inputField}
                aria-label='E-mail input'
              />
              {showError && !isEmailValid && (

                <InfoMessage
                  message={
                    !email.length
                      ? 'Feltet er påkrevd'
                      : 'Vennligst legg inn en gyldig epost'
                  }
                />
              )}
              <div className={styles.phoneWrapper}>
                <Input
                  value={countryCode}
                  onChange={(value) => setCountryCode(value)}
                  error={showError && !isCountryCodeValid}
                  placeholder='+46'
                  name='countryCode'
                  type='tel'
                  className={cn(styles.countryCode, styles.inputField)}
                  aria-label='Country code input'
                />
                <Input
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                  error={showError && !isPhoneNumberValid}
                  placeholder='Telefonnummer'
                  name='countryCode'
                  type='tel'
                  className={cn(styles.phoneNumber, styles.inputField)}
                  aria-label='Phone number input'
                />
              </div>
              {showError && (!isCountryCodeValid || !isPhoneNumberValid) && (
                <InfoMessage
                  message={'Feltet er påkrevd'}
                />

              // <InfoMessage
              //   message={countryCodeErrorMessage + phoneErrorMessage}
              // />
              )}
              <Input
                value={position}
                onChange={(value) => setPosition(value)}
                placeholder='Befattning'
                name='position'
                className={styles.inputField}
                aria-label='Position input'
              />
              <Input
                value={company}
                onChange={(value) => setCompany(value)}
                placeholder='Företag'
                name='company' 
                className={styles.inputField}
                aria-label='Company input'
              />
            </div>
            <div className={styles.textAreaWrapper}>
              <div className={styles.textAreaImage}>
                <img src={CustomerSupport} />
                <textarea
                  className={cn(
                    styles.textArea,
                    showError && !message.length && styles.messageError
                  )}
                  type='text'
                  name='message'
                  placeholder='Meddelande'
                  rows='10'
                  cols='50'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  aria-label='Message input'
                />
              </div>
              {showError && !message.length && (
                <InfoMessage message={'Feltet er påkrevd'} />
              )}
            </div>
          </div>
          <button
            className={styles.button}
            type='submit'
            aria-label='submit button'
          >
            {'Skicka'}
          </button>
          {isErrorResponse && (
            <InfoMessage message={'Något gick fel, försök igen'} />
          )}
        </div>
        {showSuccess && (
          <div className={styles.mobileSuccessMessage}>
            <div className={styles.successMobileTitle}>
              <h4 className={styles.mobileSubTitle}>
                Tack, din förfrågan har nu registrerats
              </h4>
            </div>
            <div className={styles.successMobileIcon}>
              <Icon symbol='mailIcon' />
            </div>
          </div>
        )}
      </form>
    </Modal>
  )
}

ContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
