import React, {useState} from 'react'
import { Item } from './menu-item'
import { Menu } from './menu'
import PropTypes from 'prop-types'
import * as styles from './header.module.css'

export const SEHeader = ({ isMobile, setShowContactModal }) => {
  return (
    <>
    <div className={styles.menuWrap}>
      <Menu>
        <Item linksTo={'/product/'}>Vår lösning</Item>
        <Item linksTo={'/medication/'}>Medicineringsstöd</Item>
        <Item linksTo={'/digniocare/'}>Digital distansvård</Item>
      </Menu>
      <Menu keepMenuInViewPort>
        <Item linksTo={'/about/'}>Om oss</Item>
        {!isMobile && <Item linksTo={'/contact/'}>Kontakt</Item>}
        <Item linksTo={'https://dignio.homerun.co/'} external>
          Jobba hos oss?
        </Item>
      </Menu>
      {!isMobile && <Menu>
        <div className={styles.buttonGroup}>
          <button className={styles.button} onClick={() => setShowContactModal(true)} >Boka en demo</button>
        </div>
      </Menu>}
      {isMobile && (
        <Menu>
          <Item linksTo={'/contact/'}>Kontakt</Item>
        </Menu>
      )}
      </div>
    </>
  )
}

SEHeader.propTypes = {
  isMobile: PropTypes.bool,
  setShowContactModal: (PropTypes.func),
}
