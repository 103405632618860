/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'
import Icon from './icons'
import '../styles/layout.css'
import * as styles from './layout.module.css'
import { SocialMediaIconList } from './SocialMediaIconList'
import { useGetIsMobile } from '../hooks/useGetIsMobile'

const Item = ({ children, linksTo }) => {
  return (
    <li className={styles.item}>
      <Link to={linksTo}>{children}</Link>
    </li>
  )
}

export const Footer = () => {
  const isMobile = useGetIsMobile()
  return (
    <div className={styles.container}>
      <footer>
        <div>
          <h1 className={styles.branding}>
            <Link to='/'>
              <Icon symbol='logo3' color={'#FC4E4E'} />
            </Link>
          </h1>
        </div>
        <div className={styles.footer}>
          <div className={styles.product}>
            <ul>
              <Item linksTo={'/product/'}>
                Vår lösning
              </Item>
              <Item linksTo={'/medication/'}>
                Medicineringsstöd
              </Item>
              <Item linksTo={'/digniocare/'}>
                Digital distansvård
              </Item>
            </ul>
          </div>
          <div className={styles.solution}>
            <ul>
              <p />
              <Item linksTo={'/contact/'}>
                Kontakt
              </Item>
              <Item linksTo={'/about/'}>
                Om oss
              </Item>
              <li className={styles.item}>
                <a className={styles.link} href={'https://dignio.homerun.co/'}>
                  Jobba hos oss?
                </a>
              </li>
            </ul>
          </div>
          {!isMobile && <div className={styles.social}>
            <div className={styles.inner}>
              <p className={styles.footerHeadline}></p>
              <SocialMediaIconList />
            </div>
          </div>}
          <div className={styles.expandedCol}>
            <ul className={styles.sites}>
              <p className={styles.footerHeadline}>Visit</p>
              <a className={styles.link} href={'https://dignio.no'}>Norway  →</a>
              <a className={styles.link} href={'https://dignio.com/us'}>United States  →</a>
              <a className={styles.link} href={'https://dignio.com'}>Global site  →</a>
            </ul>
          </div>

        </div>
        {isMobile && <div className={styles.social}>
          <div className={styles.inner}>
            <p/>
            <SocialMediaIconList color='#043940'/>
          </div>
        </div>}
        <div className={styles.footerLine}></div>
      </footer>

      <div className={styles.extras}>
        <div className={styles.footerLower} to={'/index'}>
          © 2024 Dignio
        </div>

        <Link className={styles.footerLower} to={'/page/privacy-at-dignio'}>
          Integritetspolicy
        </Link>

        <Link
          className={styles.footerLower} to={'/page/terms-and-conditions-for-use'}>
          Vilkår
        </Link>

        <a
          type='subtile'
          className={styles.footerLower}
          href={'https://status.dignio.com/'}>
          Driftstatus
        </a>
      </div>
    </div>
  )
}

export default Footer
