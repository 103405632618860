import React, {useState} from 'react'
import { Link } from 'gatsby'
import Icon from '../icons'
import { cn } from '../../lib/helpers'
import { useGetIsMobile } from '../../hooks/useGetIsMobile'
import { SEHeader } from './se-header'
import PropTypes from 'prop-types'
import * as buttonStyles from '../Button/Button.module.css'
import * as styles from './header.module.css'
import Container from '../container'
import {ContactModal} from '../contact/ContactModal';

const Header = ({
  onHideNav,
  onShowNav,
  textColor,
  backgroundColor,
  showNav,
}) => {
  const isMobile = useGetIsMobile(1300)
  const [showContactModal, setShowContactModal] = useState(false)

  return (
    <div className={styles.root}>
      <div style={{ backgroundColor: backgroundColor, color: textColor }}>
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            <h1 className={styles.branding}>
              <Link to='/'>
                <Icon symbol='logo3' color={textColor} />
              </Link>
            </h1>
          </div>

          <button
            className={styles.toggleNavButton}
            onClick={showNav ? onHideNav : onShowNav}>
            <Icon symbol='hamburger' color={textColor}/>
          </button>

          <nav className={cn(styles.nav, `${isMobile ? showNav && styles.showNav : null}`)}>
            <div className={cn(styles.logoWrapper, styles.logoMobileNav)}>
              <h1 className={styles.branding}>
                <Link to='/'>
                  <Icon symbol='logo3' color={'#032821'}/>
                </Link>
              </h1>
            </div>
            <button
              className={styles.toggleNavButton}
              onClick={showNav ? onHideNav : onShowNav}>
              <span className={styles.closeMenu}>
                <Icon symbol='closeMenuIcon' color='black'/>
              </span>
            </button>
            <SEHeader isMobile={isMobile} setShowContactModal={setShowContactModal} />
            {isMobile &&
            <button className={`${buttonStyles.colorButton} ${styles.login}`} onClick={() => setShowContactModal(true)} >Boka en demo</button>}
          </nav>
        </div>
      </div>

      {showContactModal && (
        <ContactModal onClose={() => setShowContactModal(false)} />
      )}


    </div>
  )
}

Header.propTypes = {
  onHideNav: PropTypes.func,
  onShowNav: PropTypes.func,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  showNav: PropTypes.bool,
}

export default Header
